import request from '@/utils/request'

// 获取关于我们介绍详情
export function sysAboutUsGet () {
    return request({
        url: '/user-service/sysAboutUs',
        method: 'get',    
    })
}


// 提交详情
export function sysAboutUsPut (data) {
    return request({
        url:  `/user-service/sysAboutUs`,
        method: 'put',
        data
    })
}